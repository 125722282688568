import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@mindstonehq/ui";
import { Link } from "react-router";
import React, { useEffect } from "react";
import { AuthCheckProps } from "~/hooks/check-authenticated";
import metrics from "~/utils/metrics";

export default function AuthCheckDialog({
  close,
  dialogProps,
}: {
  close: () => any;
  dialogProps: AuthCheckProps;
}) {
  useEffect(() => {
    if (!!dialogProps.text) {
      metrics.track("login_wall_seen", {
        type: dialogProps.type,
      });
    }
  }, [!!dialogProps.text]);

  return (
    <Dialog
      open={!!dialogProps.text}
      onOpenChange={(o) => {
        if (!o) close();
      }}
    >
      <DialogContent>
        <DialogHeader className="flex flex-row space-x-2 items-center">
          <ArrowRightOnRectangleIcon
            className="h-6 w-6 text-foreground"
            aria-hidden="true"
          />
          <DialogTitle>Looks like you're not logged in!</DialogTitle>
        </DialogHeader>

        <DialogDescription>{dialogProps.text}</DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant={"outline"}>Cancel</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="default" onClick={close} asChild>
              <Link to={`/signup?redirectUrl=${dialogProps.redirectUrl}`}>
                <ArrowRightOnRectangleIcon />
                <span>Signup</span>
              </Link>
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
